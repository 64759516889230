import axios from "axios";

import BASE_URL from "../../../constants/baseURL";

export default async function signIn({ authToken, userID, businessEntityId }) {
  const role = sessionStorage.getItem("role");
  const steps = JSON.parse(sessionStorage.getItem("ladder"));
  const activeBrand = sessionStorage.getItem("activeBrand");
  const dashboardState = localStorage.getItem("dashboardState" + activeBrand);

  sessionStorage.setItem("userID", parseInt(userID));
  sessionStorage.setItem("authToken", authToken);
  sessionStorage.setItem("businessEntityId", businessEntityId);

  let permissions = {
    promotion: [
      { ID: "dp-campaign", page: "Campaign DP" },
      { ID: "brand-details", page: "Brand Details" },
      { ID: "company-details", page: "Company Details" },
      { ID: "profile-page", page: "Profile Details" },
    ],
    monetization: [
      { ID: "my-billings", page: "My Billing" },
      { ID: "my-campaign", page: "Campaign List" },
      { ID: "brand-details", page: "Brand Details" },
      { ID: "audience-list", page: "Audience List" },
      { ID: "template-list", page: "Template List" },
      { ID: "credit-page", page: "Credit Page" },
      { ID: "profile-page", page: "Profile Details" },
      { ID: "company-details", page: "Company Details" },
      { ID: "analytics", page: "Analytics" },
      { ID: "whatsapp-onboarding", page: "whatsApp Onboarding" },
      { ID: "botDetailsPage", page: "Bot Details" },
    ],
    SUPER_ADMIN: [
      { ID: "unverified-agencies-list", page: "Unverified Agencies List" },
      { ID: "all-boarding", page: "All DP" },
      { ID: "dp-on-boarding", page: "DP Onboarding" },
      { ID: "all-campaign", page: "Campaign List" },
      { ID: "administrator-brands", page: "Brand-list" },
      { ID: "users-list", pages: "Users List" },
      { ID: "add-campaign-stats", pages: "Add Campaign Stats" },
      { ID: "verify-account", page: "Verify Accounts" },
      { ID: "site-settings", page: "Dynamic UI" },
      { ID: "permission-settings", page: "Permissions Settings" },
      { ID: "unverified-brand-list", page: "Unverified Brand List" },
      { ID: "unverified-brand-details", page: "Unverified Brand Details" },
      { ID: "unverified-company-details", page: "Unverified Company Details" },
    ],
    AGENCY: [
      { ID: "agency-dashboard-details", page: "Agency Details" },
      { ID: "company-details", page: "Company Details" },
      { ID: "profile-page", page: "Profile Details" },
    ],
  };

  let userData = {};
  let brands = [];

  const axiosInstance = axios.create({
    baseURL: BASE_URL.adn_api.url + BASE_URL.adn_api.version,
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });

  if (role === "NORMAL_USER" || role === "AGENCY") {
    const brandsData = await axiosInstance({
      url: "/brand?businessEntityId=" + businessEntityId,
      method: "GET",
    }).then((res) => res.data.data);

    brands = getBrands(brandsData);

    userData = await axiosInstance({
      url: "/user/info?userId=" + userID,
      method: "GET",
    }).then((res) => res.data.data);

    if (brands.length > 0) {
      if (!activeBrand) {
        sessionStorage.setItem("activeBrand", brands[0].ID);
      }
    }
  }

  const categories = await axiosInstance({
    url: "/brand/getAllBusinessCategories",
    method: "GET",
  }).then((res) => res.data.data);

  const locations = await axiosInstance({
    url: "/getAllCountries",
    method: "GET",
  }).then((res) => res.data.data);

  const languages = await axiosInstance({
    url: "/getAllLanguages",
    method: "GET",
  }).then((res) => res.data.data);

  return {
    location: locations,
    authToken: authToken,
    languages: languages,
    categories: categories,
    brands: brands,
    userID: parseInt(userID),
    steps: steps,
    role: role || "",
    agencyID: userData?.agency?.agency_id || null,
    enabledChannels: userData.channels,
    agency:
      {
        agencyID: userData?.agency?.agency_id || "",
        agencyName: userData?.agency?.agency_name || "",
        agencyLogo: userData?.agency?.agency_logo || "",
        isAgencyVerified: userData?.agency?.is_agency_verified || "",
      } || {},
    agent: role === "NORMAL_USER" ? userData?.brand?.agents : [],
    isAdminVerified: userData?.user?.is_admin_verified,
    parentBrandVerified: userData.user?.is_parent_brand_verified,
    userData: {
      email: userData.user?.email || "",
      givenName: userData.user?.given_name || "",
      familyName: userData.user?.family_name || "",
      displayName: userData.user?.display_name || "",
      role: userData.user?.role_name || "",
    },
    creditDetails: userData?.credit_details || {},
    dashboardState:
      role === "NORMAL_USER"
        ? brands.length > 0
          ? dashboardState
          : "monetization"
        : role,
    dashboardPermissions:
      role === "NORMAL_USER"
        ? steps
          ? brands.length > 0
            ? permissions[dashboardState]
            : permissions.monetization
          : []
        : permissions[role],
    businessEntityID: userData.brand?.business_entity_id || businessEntityId,
    activeBrand: !!brands.find(
      (brand) => parseInt(brand.ID) === parseInt(activeBrand)
    )
      ? brands.find((brand) => parseInt(brand.ID) === parseInt(activeBrand))
      : brands.length > 0
      ? brands[0]
      : {
          ID: "",
          about: "",
          brandName: "",
          logo: "",
          languages: [],
          locations: [],
          category: [],
          mau: "",
          onlineStore: "",
          website: "",
          socialMedia: {},
          brandType: "",
        },

    brandType: !!brands.find(
      (brand) => parseInt(brand.ID) === parseInt(activeBrand)
    )
      ? brands.find((brand) => parseInt(brand.ID) === parseInt(activeBrand))
          .brandType
      : brands.length > 0
      ? brands[0].brandType
      : "",
  };

  function getBrands(brands, permissions) {
    var brandList = [];

    for (const brand of brands) {
      brandList.push({
        ID: brand.brand_id,
        about: brand.about,
        brandName: brand.brand_name,
        logo: brand.logo,
        languages: brand.languages,
        locations: brand.audience_location,
        category: brand.site_category,
        mau: brand.mau,
        onlineStore: brand.online_store,
        website: brand.website,
        socialMedia: brand.social_media_handles,
        brandType: brand.brand_type_id,
      });
    }

    return brandList;
  }
}
